<script setup>
import { storeToRefs } from "pinia"
import { usePageBuilder } from "~/stores/page-builder"
import { ref, nextTick } from "vue"
import { useUrlBuilder } from "~/stores/url-builder"
import AnalyticsHandler from "~/models/analytics/handler/Handler"

const pageBuilderStore = usePageBuilder()
const urlBuilderStore = useUrlBuilder()

const { getErrorMessage, getProjectFavIcon, media } =
  storeToRefs(pageBuilderStore)

const { userAgent, isCLH, config, token, getProjecDomain } =
  storeToRefs(urlBuilderStore)

definePageMeta({
  middleware: ["slug"],
})

const { path, fullPath } = useRoute()

const loadedRows = ref([])
const showLoading = ref(false)

const rowss = ref([])

const scriptsToLoadEachPage = ref(null)
const scriptsToLoadOnce = ref(null)
const analitycsPropertiesData = ref(null)
const seoData = ref(null)
const generalStyle = ref(null)
const mediaData = ref(null)

const props = defineProps({
  ssrPageData: {
    type: Object,
    required: false,
    default: null,
  },
  managePageStyle: {
    type: Function,
    required: false,
    default: null,
  },
})

let response
try {
  if (process.server) {
    response = await pageBuilderStore.actionGetLayoutData(
      path,
      true,
      props.ssrPageData
    )
  } else {
    response = await pageBuilderStore.actionGetLayoutData(path)
  }
} catch (error) {
  console.error(error)
} finally {
  const {
    rows,
    customScriptsLoadEachPage,
    customScriptsLoadOnce,
    analitycsData,
    seo,
    media,
    style,
  } = response

  rowss.value = rows
  scriptsToLoadEachPage.value = customScriptsLoadEachPage
  scriptsToLoadOnce.value = customScriptsLoadOnce
  analitycsPropertiesData.value = analitycsData
  seoData.value = seo
  generalStyle.value = style
  mediaData.value = media
}

definePageMeta({
  middleware: ["slug"],
})

props.managePageStyle(generalStyle.value)

const injectInlineScripts = () => {
  if (process.client) {
    scriptsToLoadEachPage.value.forEach((script) => {
      if (script.innerHTML) {
        const scriptTag = document.createElement("script")
        scriptTag.type = "text/javascript"
        scriptTag.innerHTML = script.innerHTML
        scriptTag.classList.add("load-every-time-script")
        scriptTag.setAttribute("data-page", fullPath) // Add page identifier

        if (script.tagPosition === "head") {
          document.head.appendChild(scriptTag)
        } else {
          document.body.appendChild(scriptTag)
        }
      }
    })
  }
}

onNuxtReady(() => {
  if (process.client) {
    // Ensure that analytics data is available
    if (analitycsPropertiesData?.value) {
      window.__VPAPPS__ = analitycsPropertiesData.value

      // Initialize user object if it doesn't exist
      window.__VPAPPS__.user = window.__VPAPPS__.user ?? {}

      // Safely assign token if available
      if (token?.value) {
        window.__VPAPPS__.user.token = token.value
      } else {
        console.warn("Token is not defined.")
      }
    } else {
      console.error("Analytics properties data is not available.")
    }
    // console.log("Scripts to load once", scriptsToLoadOnce.value)

    // console.log("Scripts to load each page", scriptsToLoadEachPage.value)
    // Load scripts for each page using useHead
    if (scriptsToLoadEachPage?.value) {
      injectInlineScripts()
    }
    if (analitycsPropertiesData?.value?.page?.type === "media") {
      if (mediaData.value) {
        window.__VPAPPS__.media.parent = mediaData.value.model.parents
        injectVideoObject()
      }
    }
    // Notify analytics handlers if vpanalytics is available
    if (window.vpanalytics) {
      vpanalytics.notifyHandlers("onPageView")
      vpanalytics.notifyHandlers("onWebLocation")
    } else {
      console.warn("vpanalytics is not defined.")
    }

    // Safely call savePageView if defined
    if (typeof savePageView === "function") {
      savePageView()
    } else {
      console.warn("savePageView function is not defined.")
    }
  }
})

function injectVideoObject() {
  const videoStructuredData = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    name: seoData.value?.title || "Default Video Title",
    description: seoData.value?.description || "Default Video Description",
    thumbnailUrl:
      seoData.value?.metaOg?.image ||
      "https://example.com/default-thumbnail.jpg",
    uploadDate: mediaData.value?.model.startDate || "2024-12-10T08:00:00+00:00",
    duration: mediaData.value?.model.length || "PT2M33S",
    contentUrl:
      mediaData.value?.model.assets[0]?.rootUrl ||
      "https://example.com/video.mp4",
    embedUrl: `${getProjecDomain.value}${path}`,
    interactionStatistic: {
      "@type": "InteractionCounter",
      interactionType: { "@type": "WatchAction" },
      userInteractionCount: mediaData.value?.model?.viewCount || 0,
    },
  }
  const scriptTag = document.createElement("script")
  scriptTag.type = "application/ld+json"
  scriptTag.classList.add("structured-data") // Add class to identify later
  scriptTag.setAttribute("data-page", fullPath) // Add page identifier
  scriptTag.innerHTML = JSON.stringify(videoStructuredData)
  document.head.appendChild(scriptTag)
}
onBeforeUnmount(() => {
  // Find scripts associated with the current page
  const scriptTags = document.querySelectorAll(
    `script.load-every-time-script[data-page="${fullPath}"]`
  )
  scriptTags.forEach((scriptTag) => {
    scriptTag.parentNode.removeChild(scriptTag)
  })

  const structuredDataScripts = document.querySelectorAll(
    `script.structured-data[data-page="${fullPath}"]`
  )
  structuredDataScripts.forEach((scriptTag) => {
    scriptTag.parentNode.removeChild(scriptTag)
  })
})

const savePageView = () => {
  const analyticsHandler = new AnalyticsHandler()
  analyticsHandler.sendPageView()
}

onMounted(() => {
  const pageEl = document.querySelector(".vp-app")
  if (pageEl && generalStyle.value) {
    pageEl.style.background = generalStyle.value.background
  }

  showLoading.value = true
  const limitToLoadMoreRowEl = document.querySelector(".limit-to-load-row")
  if (limitToLoadMoreRowEl) {
    useIsElementOnView(limitToLoadMoreRowEl, loadRows)
    limitToLoadMoreRowEl.style.backgroundColor =
      generalStyle.value?.backgroundColor
  }
})

const robotRules = computed(() => {
  if (
    seoData.value &&
    seoData.value.robot &&
    Object.keys(seoData.value.robot).length > 0
  ) {
    return Object.keys(seoData.value.robot)
      .filter((key) => seoData.value.robot[key])
      .map((r) => r?.toLowerCase())
      .join(", ")
  }

  return false
})

const canonicalUrl = computed(() => {
  return `${getProjecDomain.value}${path}`
})

// useHead({
//   script: [
//     {
//       type: "application/ld+json",
//       children: JSON.stringify(videoStructuredData.value),
//     },
//   ],
// })

const backgroundColor = computed(() => {
  return { backgroundColor: generalStyle.value?.backgroundColor }
})

const loadRows = (data = 3) => {
  const nextRows = rowss.value?.splice(0, data)
  loadedRows.value.push(...nextRows)

  nextTick(() => {
    if (!rowss.value || rowss.value.length <= 0) {
      showLoading.value = false
    }
  })
}

loadRows(4)
</script>

<template>
  <div class="page flex-grow flex flex-col overflow-visible">
    <!-- <pre>

      {{ ssrPageData }}
    </pre> -->
    <!-- <pre class="text-red-300 fixed left-0 top-2/3 bottom-0 bg-green-900 z-50 overflow-scroll">
     --{{ rowss }}
    </pre> -->

    <Head v-if="seoData">
      <Meta v-if="robotRules" name="robots" :content="robotRules" />

      <Link rel="dns-prefetch" href="https://cdn.vpapps.cloud/" />
      <Link rel="icon" :href="getProjectFavIcon" />
      <Link rel="canonical" :href="canonicalUrl" />
      <Title>{{ seoData?.title }}</Title>
      <Meta name="description" :content="seoData?.description" />
      <Meta name="author" :content="seoData?.author" />

      <Meta property="og:title" :content="seoData?.metaOg?.title" />
      <Meta property="og:description" :content="seoData?.metaOg?.description" />
      <Meta property="og:image" :content="seoData?.metaOg?.image" />

      <!-- <Meta property="twitter:title" :content="seoData?.twitterCard?.title" />
      <Meta property="twitter:description" :content="seoData?.twitterCard?.description" />
      <Meta property="twitter:image" :content="seoData?.twitterCard?.image" /> -->
      <Meta name="twitter:card" content="summary" />
      <Meta name="twitter:url" :content="`${getProjecDomain}${path}`" />
      <Meta name="twitter:title" :content="seoData?.twitterCard?.title" />
      <Meta
        name="twitter:description"
        :content="seoData?.twitterCard?.description"
      />
      <Meta name="twitter:image" :content="seoData?.twitterCard?.image" />
    </Head>

    <TheBody>
      <div class="j-body" :style="backgroundColor">
        <div v-if="getErrorMessage" class="hidden">
          Error {{ getErrorMessage }}
        </div>
        <PageNotFound
          v-if="
            rowss.length <= 0 && loadedRows.length <= 0 && path !== '/results'
          "
        />

        <div class="w-full h-full">
          <template v-for="row in loadedRows" :key="row.id">
            <Identifier :component="row" :rowOrder="row.order" />
          </template>
        </div>
      </div>
    </TheBody>
    <div class="j-spacer flex-grow" :style="backgroundColor"></div>
    <div
      v-show="showLoading"
      id="load-more-rows"
      :style="backgroundColor"
      class="load-more-row flex justify-center flex-col items-center"
    >
      <div
        class="border-gray-300 h-8 w-8 animate-spin rounded-full border-8 border-t-blue-600 my-8"
      />
    </div>
  </div>
</template>
